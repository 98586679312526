import { AxiosError } from 'axios';
import { makeObservable, observable, action, computed, flow } from 'mobx';
import fetchUser, {
  getUsersCount,
  IUser,
  IUsersStatResponse,
  getAllUsersStat,
  getUserById
} from 'requests/users';
import parse from 'date-fns/parse';
import { RootStore } from 'store';

export const userKeyToLabelMap = {
  inserted_at: 'Created at',
  id: 'User ID',
  full_name: 'Full Name',
  phone_number: 'Phone Number',
  email: 'Email',
  user_holdings: 'Portfolio',
  status: 'Status',
  updated_at: 'Updated at',
  verified: 'Verified',
  funded_account: 'Account Funded',
  date_of_birth: 'DOB',
  address: 'Address',
  kyc_status: 'KYC Status',
  identity_image: 'Identity Image',
  identifier: 'Identifier',
  gender: 'Gender'
};
export interface ITableformattedUser {
  id: string;
  phone_number: string;
  verified: boolean;
  inserted_at: string;
  updated_at: string;
  funded_account: boolean;
  user_holdings: Omit<IStockHolding, 'logo_url'>[];
  email: string; // > users_basic_info.email
  date_of_birth: string; // > identity_document?.date_of_birth || users_basic_info.date_of_birth
  full_name: string; // > identity_document?.full_name || users_basic_info.name & users_basic_info.surname
  address: string;
  status: 'active' | 'inactive';
  identity_image: IIdentityImageResponse;
  identifier: string;
  gender: string;
}

export default class UserStore {
  users: IUser[] = [];

  user: Nullable<IUser> = null;

  selectedUser: Nullable<ITableformattedUser> = null;

  selectedUserId: string = '';

  usersStat: IUsersStatResponse = {
    active_users: 0,
    inactive_users: 0,
    users_count: 0
  };

  isLoading = {
    usersStat: false,
    fetchOneUser: false,
    fetchUser: false
  };

  usersCount: number = 0;

  fetchingUserCount: boolean = false;

  error: Partial<Error> = {};

  meta: Partial<IPaginatedMeta> = {
    currentPage: 0,
    itemCount: 0
  };

  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    makeObservable(this, {
      users: observable,
      error: observable,
      meta: observable,
      usersCount: observable,
      fetchingUserCount: observable,
      usersStat: observable,
      isLoading: observable,
      selectedUser: observable,
      selectedUserId: observable,
      user: observable,

      setReqError: action.bound,
      selectUser: action.bound,
      selectUserId: action.bound,

      fetchUser: flow.bound,
      getUserCount: flow.bound,
      fetchUserStat: flow.bound,
      fetchOneUser: flow.bound,

      getUserView: computed,
      UserViewById: computed
    });

    this.rootStore = rootStore;
  }

  setReqError(err: AxiosError) {
    if (err.response?.status !== 401) {
      this.error = err;
      setTimeout(() => {
        this.error = {};
      }, 10000);
    }
  }

  selectUser(user?: Nullable<ITableformattedUser>) {
    this.selectedUser = user;
  }

  selectUserId(user_id: Nullable<string>) {
    if (!user_id) this.user = null;
    this.selectedUserId = user_id;
  }

  *fetchUser(payload: Partial<IPaginationConfig>) {
    this.isLoading.fetchUser = true;
    try {
      const { data } = yield fetchUser(payload);
      this.users = data.items;
      this.meta = data.meta;
    } catch (error) {
      this.setReqError(error);
    } finally {
      this.isLoading.fetchUser = false;
    }
  }

  *fetchOneUser(user_id: string) {
    this.isLoading.fetchOneUser = true;
    try {
      const { data } = yield getUserById({ user_id });

      this.user = data.item;
    } catch (error) {
      this.setReqError(error);
    } finally {
      this.isLoading.fetchOneUser = false;
    }
  }

  *getUserCount() {
    this.fetchingUserCount = true;
    try {
      const { data } = yield getUsersCount();
      this.usersCount = data;
    } catch (error) {
      this.setReqError(error);
    } finally {
      this.fetchingUserCount = false;
    }
  }

  *fetchUserStat() {
    this.isLoading.usersStat = true;
    try {
      const { data } = (yield getAllUsersStat()) as { data: IUsersStatResponse };
      this.usersStat = data;
    } catch (error) {
      this.setReqError(error);
    } finally {
      this.isLoading.usersStat = false;
    }
  }

  get getUserView() {
    return this.users.map<ITableformattedUser>((user) => ({
      id: user.id,
      phone_number: user.phone_number,
      address: user.identity_document?.address || '',
      date_of_birth: user.identity_document?.date_of_birth
        ? parse(user.identity_document.date_of_birth, 'dd-MM-yyyy', new Date()).toISOString()
        : `${user.users_basic_info.date_of_birth}`,
      email: user.users_basic_info.email,
      full_name:
        user.identity_document?.full_name ||
        `${user.users_basic_info.name} ${user.users_basic_info.surname}`,
      funded_account: user.funded_account,
      inserted_at: user.inserted_at,
      updated_at: user.updated_at,
      user_holdings: user.user_holdings,
      verified: user.verified || Boolean(user.identity_document),
      status: user.identity_document && user.funded_account ? 'active' : 'inactive',
      identity_image: user.identity_image,
      identifier: user.identity_document?.identifier || 'N/A',
      gender: user.identity_document.gender || 'N/A'
    }));
  }

  get UserViewById() {
    if (!this.user) return null;
    return {
      id: this.user.id,
      phone_number: this.user.phone_number,
      address: this.user.identity_document?.address || '',
      date_of_birth: this.user.identity_document?.date_of_birth
        ? parse(this.user.identity_document.date_of_birth, 'dd-MM-yyyy', new Date()).toISOString()
        : `${this.user.users_basic_info.date_of_birth}`,
      email: this.user.users_basic_info.email,
      full_name:
        this.user.identity_document?.full_name ||
        `${this.user.users_basic_info.name} ${this.user.users_basic_info.surname}`,
      funded_account: this.user.funded_account,
      inserted_at: this.user.inserted_at,
      updated_at: this.user.updated_at,
      user_holdings: this.user.user_holdings,
      verified: this.user.verified || Boolean(this.user.identity_document),
      status: this.user.identity_document && this.user.funded_account ? 'active' : 'inactive',
      identifier: this.user.identity_document?.identifier || 'N/A',
      gender: this.user.identity_document.gender || 'N/A'
    } as ITableformattedUser;
  }
}
