import { AxiosError } from 'axios';
import addDays from 'date-fns/addDays';
import addMonths from 'date-fns/addMonths';
import addWeeks from 'date-fns/addWeeks';
import format from 'date-fns/format';
import { makeObservable, observable, action, runInAction, computed, flow } from 'mobx';
import getWithdrawal, {
  IWithdrawal,
  getSummaryStat,
  IWithdrawalSummaryStat
} from 'requests/witdrawals';
import { RootStore } from 'store';
import { getDateRangeWithLabel } from 'utils';

export const withdrawalKeyToLabelMap = {
  inserted_at: 'Date',
  id: 'Tx ID',
  full_name: 'Customer',
  amount: 'Amount',
  exchange_rate_value: 'Rate',
  fees: 'Fee',
  status: 'Status',
  currency: 'Currency',
  account_number: 'Account Number',
  bank_name: 'Bank',
  additional_instructions: 'Description',
  updated_at: 'Updated at',
  user_id: 'User ID'
};

export interface ITableFormattedWithdrawal {
  id: string;
  inserted_at: string;
  full_name: string;
  account_number: string;
  additional_instructions: string | null;
  amount: string;
  bank_name: string;
  currency: string;
  fees: string;
  status: string;
  updated_at: string;
  exchange_rate_value: string;
  user_id: string;
}

const { endDate, label, startDate } = getDateRangeWithLabel('Last 7 Days');

export default class WithdrawalStore {
  withdrawals: IWithdrawal[] = [];

  fetching: boolean = false;

  error: Partial<Error> = {};

  meta: Partial<IPaginatedMeta> = {
    currentPage: 0,
    itemCount: 0
  };

  summaryStat: IWithdrawalSummaryStat = {
    data: [],
    range: {
      end_date: 0,
      start_date: 0
    },
    stat: 'days'
  };

  loading: Partial<{
    deposit: boolean;
    summaryStat: boolean;
  }> = {};

  tableConfig: ISetDateRangeCBArgs & ITableConfig = {
    endDate,
    startDate,
    buttonLabel: label,
    reset: false
  };

  errors: Partial<{
    summaryStat: Partial<Error>;
  }> = {};

  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    makeObservable(this, {
      withdrawals: observable,
      fetching: observable,
      error: observable,
      errors: observable,
      meta: observable,
      summaryStat: observable,
      tableConfig: observable,
      fetchWithdrawal: action.bound,
      setReqError: action.bound,
      setRange: action.bound,
      toggleReset: action.bound,
      fetchStat: flow.bound,
      getWithdrawalView: computed,
      calculatedWithdrawalSum: computed,
      chartData: computed
    });

    this.rootStore = rootStore;
  }

  setRange(config: ISetDateRangeCBArgs) {
    this.tableConfig = { ...config, reset: true };
    this.withdrawals = [];
    this.fetchStat({ start_date: config.startDate.valueOf(), end_date: config.endDate.valueOf() });
  }

  toggleReset(reset: boolean) {
    this.tableConfig.reset = reset;
  }

  setReqError(err: AxiosError, state: string = 'error') {
    if (err.response?.status !== 401) {
      this[state] = err;
      setTimeout(() => {
        this[state] = {};
      }, 10000);
    }
  }

  fetchWithdrawal({ limit, page, start_date, end_date }: Partial<IPaginationConfig> = {}) {
    runInAction(() => {
      this.fetching = true;
    });
    getWithdrawal(page, limit, start_date, end_date)
      .then(({ data }) => {
        runInAction(() => {
          this.withdrawals = data.items;
          this.meta = data.meta;
        });
      })
      .catch((error) => {
        this.setReqError(error);
      })
      .finally(() => {
        runInAction(() => {
          this.fetching = false;
        });
      });
  }

  *fetchStat({ start_date, end_date }: Partial<IPaginationConfig> = {}) {
    this.loading.summaryStat = true;
    try {
      const { data } = yield getSummaryStat(start_date, end_date);
      this.summaryStat = data;
      this.loading.summaryStat = false;
    } catch (error) {
      this.setReqError(error, 'errors.summaryStat');
    }
  }

  get getWithdrawalView() {
    return this.withdrawals.map<ITableFormattedWithdrawal>((withdrawal) => ({
      id: withdrawal.id,
      inserted_at: withdrawal.inserted_at,
      account_number: withdrawal.account_number,
      additional_instructions: withdrawal.additional_instructions,
      amount: withdrawal.amount,
      bank_name: withdrawal.bank_name,
      currency: withdrawal.currency,
      exchange_rate_value: withdrawal.exchange_rate_value,
      fees: withdrawal.fees,
      full_name: withdrawal.user?.identity_document?.full_name || 'Not available',
      status: withdrawal.status,
      updated_at: withdrawal.updated_at,
      user_id: withdrawal.user_id
    }));
  }

  get calculatedWithdrawalSum() {
    return this.summaryStat.data.reduce((prev, currVal) => currVal.sum / 100 + prev, 0);
  }

  get chartData(): IChartData {
    const statData = this.summaryStat;
    if (statData.stat === 'days') {
      const res = statData.data.map<IChartData['data'][0]>((day) => ({
        label: format(addDays(statData.range.start_date, day.id - 1), 'd MMM'),
        value: Number(day.sum / 100).toFixed(2)
      }));
      return {
        label: `Summary of ${format(statData.range.start_date, 'PPP')} ~ ${format(
          statData.range.end_date,
          'PPP'
        )}`,
        data: res
      };
    }
    if (statData.stat === 'months') {
      const res = statData.data.map<IChartData['data'][0]>((month) => ({
        label: format(addMonths(statData.range.start_date, month.id - 1), 'MMM'),
        value: Number(month.sum / 100).toFixed(2)
      }));
      return {
        label: `Summary of ${format(statData.range.start_date, 'PPP')} ~ ${format(
          statData.range.end_date,
          'PPP'
        )}`,
        data: res
      };
    }
    if (statData.stat === 'weeks') {
      const res = statData.data.map<IChartData['data'][0]>((weeks) => ({
        label: format(addWeeks(statData.range.start_date, weeks.id - 1), "wo 'week'"),
        value: Number(weeks.sum / 100).toFixed()
      }));
      return {
        label: `Summary of ${format(statData.range.start_date, 'PPP')} ~ ${format(
          statData.range.end_date,
          'PPP'
        )}`,
        data: res
      };
    }
    return {
      data: [],
      label: ''
    };
  }
}
