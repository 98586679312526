const getWithdrawals = ({ page, limit, end_date, start_date }: IPaginationConfig) =>
  `/withdrawals?page=${page}&limit=${limit}${start_date ? `&start_date=${start_date}` : ''}${
    end_date ? `&end_date=${end_date}` : ''
  }`;

const getWithdrawalSummaryStat = ({
  start_date,
  end_date
}: Omit<IPaginationConfig, 'page' | 'limit'>) =>
  `/withdrawals/sum?start_date=${start_date}&end_date=${end_date}`;

const lifeTimeTradeSum = '/trades/life_time_trades_sum';

const getUsersStat = '/users/stat';

const getUserCount = '/users/number_of_users';

const stat = '/stats/life_time_values';

const downloadCSV = (
  record: TRecordTableDomain,
  { end_date, start_date }: Required<Omit<IPaginationConfig, 'limit' | 'page' | 'q'>>
) => `/${record}/download_csv?start_date=${start_date}&end_date=${end_date}`;

const auth = {
  login: '/auth/signin',
  logout: '/auth/sign_out',
  tenantDetails: '/auth/admin',
  resetPassword: '/auth/reset_password',
  verify2fa: '/auth/verify',
  refreshToken: '/auth/refresh_token'
};

export const AUTH_MS = {
  CHECK_EMAIL: '/auth/check_email/:email',
  SIGNIN_PASSWORD: '/auth/sign_in',
  VERIFY_SIGNUP_LINK: '/auth/verify_sign_up',
  SET_PASSWORD: '/auth/create_sign_up_password',
  VERIFY_OTP: '/auth/verify_otp',
  ROLES_LIST: '/roles',
  REFRESH_TOKEN: '/auth/refresh_token',
  RESET_PASSWORD: 'auth/request_reset_password/:email',
  CHANGE_PASSWORD: '/auth/change_my_password'
} as const;

export const TRADES = {
  GET_US: '/trades',
  GET_NGX: '/trades/lsx',
  GET_NGX_STAT: '/trades/lsx/sum',
  GET_US_STAT: '/trades/sum'
} as const;

export const TXN = {
  GET_DEPOSITS: '/deposits',
  GET_DEPOSITS_SUMMARY: '/deposits/sum'
} as const;

export const USERS = {
  GET: '/users',
  GET_ONE: '/users/:user_id'
} as const;

export {
  getWithdrawals,
  auth,
  downloadCSV,
  getUserCount,
  getWithdrawalSummaryStat,
  lifeTimeTradeSum,
  stat,
  getUsersStat
};
