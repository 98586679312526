import { AxiosError, AxiosResponse } from 'axios';
import { makeObservable, observable, flow, action, runInAction, computed } from 'mobx';
import {
  getCheckEmail,
  encryptedLogin,
  verify2fa,
  refreshToken,
  getChangePassword
} from 'requests/auth';
import tenantDetails from 'requests/auth/tenantDetails';
import { IVerifyParam, IVerifyResponse } from 'requests/auth/verify2fa';
import jwt from 'jsonwebtoken';
import { ISSERVER, RSAEncrypt } from 'utils';
import store from 'store2';
import { SK } from 'constants/persistence';
import { Page } from 'constants/route';
import router from 'next/router';
import { getRoles } from 'requests/auth/login';
import { RootStore } from 'store';

interface IJwtPayloadExt extends jwt.JwtPayload {
  name: string;
  role: string;
  permissions: string[];
  admin_user_id: number;
  api_consumer_id: number;
  email: string;
}

function decodeJWT(token: string) {
  return jwt.decode(token) as IJwtPayloadExt;
}

const persist = <T = string>(key: string, value: T) => {
  store.namespace('auth').session.set(key, value);
  return value;
};

const get = <T = string>(key: string, fallback?: T) =>
  store.namespace('auth').session.get(key, fallback) as T;

type TIsAuthenticated = { ttl: number; admin_token?: string; email?: string };

export enum EAuthStage {
  CHECK_EMAIL = 'check_email',
  PASSWORD_CHALLENGE = 'password_challenge',
  TWO_FA_CHALLENGE = 'two_fa_challenge',
  AUTHENTICATED = 'authenticated',
  INITIALIZING_ACCOUNT = 'initializing_account',
  IDLE = 'idle',
  CHANGE_PASSWORD = 'change_password'
}

const INIT_IS_LOADING = {
  login: false,
  logout: false,
  verify: false,
  refresh: false,
  checkEmail: false,
  changePassword: false,
  fetchRoles: false
};

const INIT_REQ_ERRORS = {
  login: '',
  logout: '',
  verify: '',
  refresh: '',
  checkEmail: '',
  changePassword: '',
  fetchRoles: ''
};

export default class AuthStore {
  accessToken = get('admin_token');

  authStage: EAuthStage = EAuthStage.IDLE;

  Auth: IAuth = { tenant: null };

  tokenExpiresAt = 0;

  cardSlideDir: 'left' | 'right' = 'right';

  signinEmail = '';

  tempEmailObj = get<Record<string, string>>(SK.AUTH_LOGIN_EMAIL_MAP, {});

  currentEmailKey = '';

  persistedLoginCred = '';

  verificationCodeResent = false;

  resetingPassword = false;

  tenantDetails: Nullable<ITenantDetailsResponse> = null;

  roles = get<IBATRoles[]>(SK.AU_ROLES, []);

  loggedOut = true;

  passwordChanged = false;

  isLoading = { ...INIT_IS_LOADING };

  errors = { ...INIT_REQ_ERRORS };

  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    makeObservable(this, {
      accessToken: observable,
      loggedOut: observable,
      isLoading: observable,
      errors: observable,
      tenantDetails: observable,
      tokenExpiresAt: observable,
      authStage: observable,
      Auth: observable,
      cardSlideDir: observable,
      signinEmail: observable,
      tempEmailObj: observable,
      currentEmailKey: observable,
      passwordChanged: observable,
      persistedLoginCred: observable,
      verificationCodeResent: observable,
      resetingPassword: observable,
      roles: observable,

      setReqError: action.bound,
      sessionCleanup: action.bound,
      isAuthenticated: action.bound,
      setAuthStage: action.bound,
      refreshToken: action.bound,
      reset: action.bound,
      setCardSlideDir: action.bound,
      logout: action.bound,
      setResetingPassword: action.bound,
      updateUserObject: action.bound,

      login: flow.bound,
      fetchTenantDetails: flow.bound,
      verify: flow.bound,
      checkEmail: flow.bound,
      changePassword: flow.bound,
      fetchRoles: flow.bound,

      AuthUser: computed,
      Roles: computed,
      Permissions: computed
    });

    this.rootStore = rootStore;
  }

  reset() {
    this.accessToken = '';
    this.authStage = EAuthStage.IDLE;
    this.isLoading = { ...INIT_IS_LOADING };
    this.errors = { ...INIT_REQ_ERRORS };
    this.roles = get<IBATRoles[]>(SK.AU_ROLES, []);
  }

  setReqError(err: AxiosError, state: string = 'error') {
    if (err.response.status !== 401) {
      this[state] = err;
      setTimeout(() => {
        this[state] = {};
      }, 10000);
    }
  }

  clearError(errorItem: keyof typeof this.errors, delay = 3000) {
    setTimeout(() => {
      runInAction(() => {
        this.errors[errorItem] = '';
      });
    }, delay);
  }

  setAuthStage(stage: EAuthStage) {
    this.authStage = stage;
  }

  setCardSlideDir(dir: 'left' | 'right') {
    this.cardSlideDir = dir;
  }

  setResetingPassword(reseting: boolean) {
    this.resetingPassword = reseting;
  }

  updateUserObject(jwtPayload: IJwtPayloadExt) {
    this.tenantDetails = {
      ...this.tenantDetails,
      email: jwtPayload.email,
      name: jwtPayload.name,
      permissions: jwtPayload.permissions,
      role_name: jwtPayload.role.toLowerCase()
    };
  }

  *checkEmail(email: string) {
    this.cardSlideDir = 'right';
    try {
      this.isLoading.checkEmail = true;
      const resp = (yield getCheckEmail(email)) as AxiosResponse;

      this.signinEmail = email;
      if (resp.status === 200) {
        this.authStage = EAuthStage.PASSWORD_CHALLENGE;

        this.currentEmailKey = Date.now().toString();
        this.tempEmailObj = persist(SK.AUTH_LOGIN_EMAIL_MAP, {
          [this.currentEmailKey]: email,
          ...this.tempEmailObj
        });
        setTimeout(() => {
          runInAction(() => {
            this.currentEmailKey = '';
          });
        }, 1000);
      }
      if (resp.status === 206) this.authStage = EAuthStage.INITIALIZING_ACCOUNT;
    } catch (error) {
      this.errors.checkEmail = error.response?.data?.message || error.message;
      this.clearError('checkEmail');
      this.authStage = EAuthStage.CHECK_EMAIL;
    } finally {
      this.isLoading.checkEmail = false;
    }
  }

  *verify({ code }: IVerifyParam & { email?: string }) {
    this.isLoading.verify = true;
    try {
      const { data } = (yield verify2fa({ code })) as {
        data: IVerifyResponse;
      };
      // this.tenantDetails.email = email || this.tenantDetails.email;
      this.accessToken = persist('admin_token', data.access_token);
      this.isAuthenticated(data.access_token);
      this.fetchTenantDetails();
      this.authStage = EAuthStage.AUTHENTICATED;
      this.persistedLoginCred = '';
    } catch (error) {
      this.errors.verify = error.response?.data?.message || error.message;
      setTimeout(() => {
        runInAction(() => {
          this.errors.verify = '';
        });
      }, 5000);
    } finally {
      this.isLoading.verify = false;
    }
  }

  *changePassword(payload: Omit<IChangePassword, 'confirm_new_password'>) {
    this.isLoading.changePassword = true;
    this.passwordChanged = false;
    try {
      yield getChangePassword(RSAEncrypt(JSON.stringify(payload)));
      this.passwordChanged = true;
    } catch (error) {
      this.errors.changePassword = error.response?.data?.message || error.message;
      setTimeout(() => {
        runInAction(() => {
          this.errors.changePassword = '';
        });
      }, 5000);
    } finally {
      this.isLoading.changePassword = false;
    }
  }

  refreshToken() {
    this.isLoading.refresh = true;
    refreshToken()
      .then(({ data }) => {
        runInAction(() => {
          this.accessToken = persist('admin_token', data.access_token);
        });
        this.isAuthenticated(data.access_token);
      })
      .catch((error) => {
        runInAction(() => {
          this.errors.refresh = error.response?.data?.message || error.message;
        });
        setTimeout(() => {
          runInAction(() => {
            this.errors.refresh = '';
          });
        }, 5000);
      })
      .finally(() => {
        runInAction(() => {
          this.isLoading.refresh = false;
        });
      });
  }

  *login(scrambledCred?: string) {
    this.isLoading.login = true;
    try {
      if (!scrambledCred && !this.persistedLoginCred) {
        this.authStage = EAuthStage.CHECK_EMAIL;
        return;
      }
      if (scrambledCred) {
        this.persistedLoginCred = scrambledCred;
      }
      yield encryptedLogin(scrambledCred || this.persistedLoginCred);
      if (!scrambledCred && this.persistedLoginCred) {
        this.verificationCodeResent = true;
        setTimeout(() => {
          runInAction(() => {
            this.verificationCodeResent = false;
          });
        }, 5000);
      }
      this.authStage = EAuthStage.TWO_FA_CHALLENGE;
    } catch (error) {
      this.errors.login = error.response?.data?.message || error.message;
      setTimeout(() => {
        runInAction(() => {
          this.errors.login = '';
        });
      }, 15_000);
    } finally {
      this.isLoading.login = false;
    }
  }

  *fetchTenantDetails() {
    try {
      const { data } = (yield tenantDetails()) as { data: ITenantDetailsResponse };
      this.tenantDetails = data;
      this.Auth.tenant = {
        email: data.email,
        scopes: data.scopes
      };
    } catch (e) {
      const error = e as AxiosError;
      this.errors.login = error.response?.data?.message || error.message;
      setTimeout(() => {
        runInAction(() => {
          this.errors.login = '';
        });
      }, 5000);
    }
  }

  sessionCleanup() {
    if (!ISSERVER) sessionStorage.clear();
    this.accessToken = '';
    this.tokenExpiresAt = 0;
    this.loggedOut = true;
    this.Auth = {};
  }

  /** Returns TTL in milliseconds  */
  isAuthenticated(admin_token: string = this.accessToken): TIsAuthenticated {
    const token = admin_token || !ISSERVER ? get('admin_token') : null;
    if (!token) {
      this.sessionCleanup();
      return { ttl: 0 };
    }
    const decodedToken = decodeJWT(token);
    const tokenExpires = (decodedToken?.exp || 0) * 1000 || this.tokenExpiresAt;
    const authenticatedTTL = tokenExpires - new Date().valueOf();
    if (authenticatedTTL < 0) {
      this.sessionCleanup();
      return { ttl: 0 };
    }
    this.accessToken = token;
    this.tokenExpiresAt = tokenExpires;
    this.loggedOut = false;
    this.authStage = EAuthStage.AUTHENTICATED;
    return { ttl: authenticatedTTL, admin_token: token };
  }

  logout(forced = false) {
    // forced detarmines if logout should be soft or hard
    this.isLoading.login = true;
    try {
      this.sessionCleanup();
      if (!ISSERVER && forced) {
        window.location.href = Page.Auth.login;
        return;
      } // hard logout
      // soft logout: todo: implement to pop-out a login modal instead of re-routing to login page
      router.replace(Page.Auth.login).then(() => {
        this.reset();
      });
    } catch (error) {
      this.errors.logout = error.response?.data?.message || error.message;
      setTimeout(() => {
        this.errors.logout = '';
      }, 5000);
    } finally {
      this.isLoading.login = false;
    }
  }

  *fetchRoles() {
    try {
      this.isLoading.fetchRoles = true;
      const { data } = (yield getRoles()) as { data: { data: IBATRoles[] } };
      this.roles = persist(SK.AU_ROLES, data.data);
    } catch (error) {
      this.errors.fetchRoles = error.response?.data?.message || error.message;
    } finally {
      this.isLoading.fetchRoles = false;
    }
  }

  get AuthUser() {
    if (this.tenantDetails) return this.tenantDetails;
    this.fetchTenantDetails();
    return this.tenantDetails;
  }

  get Roles(): Record<string, string[]> {
    return this.roles.reduce(
      (roleMap, role) => ({ ...roleMap, [role.name.toLowerCase()]: role.permissions }),
      {}
    );
  }

  get Permissions() {
    if (!this.tenantDetails) return [];
    if (!this.accessToken) return [];
    if (!this.tenantDetails.role_name || !this.tenantDetails.permissions) {
      this.updateUserObject(decodeJWT(this.accessToken));
    }
    return [...(this.Roles[this.tenantDetails.role_name || ''] || [])].concat(
      this.tenantDetails.permissions || []
    );
  }
}
