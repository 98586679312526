const STAGING_API_URL = 'https://ptn-dashboard-api.investbamboo.dev/';

const AUTH_MS_PUBLIC_KEY =
  '-----BEGIN RSA PUBLIC KEY-----\nMIIBCgKCAQEAsF2d9WpHgBqi0Sa2M5mL1Ij/PmQCKyOkTdg46PQvQSYguOV9xtoT\nK+4hwldxvQj74YZO1MZ9ZUiXqLGEJBpWGc7FZIU3rrulkj8s6WoZVz3uwq53QN3j\nW8VWXo89tlnY7eA3YKTiA1uRx/abB4gw67uX7FnDVQmcheitG37HkORFCBWTTFKU\nn8397tdbdXmQxzazmM4wpfUd7EpEFYGfEKEsA/DyAhZdjEbU1CeQHeI9QtcJYmMN\nDiV4SEcVVseSokhdTRW4ElzEdVyfOD27pOCdpRRvjrUunFxwKOTtdJYEPubwM5+z\n2hllzkvMkXF/PJf5U4OthWW9Xv1Qu7HDyQIDAQAB\n-----END RSA PUBLIC KEY-----\n';

const APP_PUBLIC_KEY =
  '-----BEGIN RSA PUBLIC KEY-----\nMIIBCgKCAQEAwd6jHoFmO73Z5p3220pNbMAxyHk30m8DMe9rOjTsPCKcqCHSc4K3\nPwqdDrJqvowSZ9Az4P6+p6/oMI6FroKVbZdnqkuj3EDm6DkpldTygH/COMJ259c+\nvDzAge5kiwRGX2OD/fjaCua57tjYAfy55GIgfZFrZfUBwBq2L5fD6ApVZft+wAem\nmUP+1+uuAS3yma+KpRLoSSFyP8EZkKTzSxua4chIEQWNGObkZTiwdEpaVGS1Qnd6\nqcooe40vZ6Q7xpfTcItFMaEXizRe7f6lHGYM6itaqKYVSRcvoRssakhWqBFPqtYk\ntrygBORwDf3L/apO7XqC1RFJeSHNWjNtaQIDAQAB\n-----END RSA PUBLIC KEY-----\n';

const AppConfig = {
  ApiBaseUrl: process.env.NEXT_PUBLIC_API_URL || STAGING_API_URL,
  AuthMSBaseUrl: process.env.NEXT_PUBLIC_AUTH_MS_BASE_URL || 'https://auth-api.investbamboo.dev/',
  AuthMSPublicKey: process.env.NEXT_PUBLIC_AUTH_MS_PKC || AUTH_MS_PUBLIC_KEY,
  AppPublicKey: process.env.NEXT_PUBLIC_APP_PKC || APP_PUBLIC_KEY
};

export default AppConfig;
