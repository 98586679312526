import { downloadCSV, getUserCount, USERS, getUsersStat } from 'constants/api';
import { rangeData } from 'constants/dateRange';
import addMonths from 'date-fns/addMonths';
import client from '..';

export interface IUser {
  id: string;
  phone_number: string;
  verified: boolean;
  inserted_at: string;
  updated_at: string;
  funded_account: boolean;
  user_holdings: Omit<IStockHolding, 'logo_url'>[];
  users_basic_info: IUserBasic;
  identity_document: IIdentityDocument;
  identity_image: IIdentityImageResponse;
}

export interface IUsersStatResponse {
  users_count: number;
  active_users: number;
  inactive_users: number;
}

const range = rangeData.find((item) => item.label === 'Last 7 Days');

const defaultData = {
  page: 1,
  limit: 25,
  start_date: range.startDate.valueOf(),
  end_date: range.endDate.valueOf()
};

const call = (payload: Partial<IPaginationConfig>) =>
  client.get<IPaginatedEndpointResp<IUser>>(USERS.GET, {
    params: { ...defaultData, ...payload }
  });

export const getUserById = ({ user_id }: { user_id: string }) =>
  client.get<IPaginatedEndpointResp<IUser>>(USERS.GET_ONE.replace(':user_id', user_id));

export const download_csv = ({
  domain,
  end_date = new Date().valueOf(),
  start_date = addMonths(new Date(), -1).valueOf()
}: Omit<IPaginationConfig, 'limit' | 'page'> & { domain: TRecordTableDomain }) =>
  client.get(downloadCSV(domain, { end_date, start_date }), {
    responseType: 'blob'
  });

export const getUsersCount = () => client.get<number>(getUserCount);

export const getAllUsersStat = () => client.get<IUsersStatResponse>(getUsersStat);

export default call;
