import AppConfig from 'app-config';
import { publicEncrypt } from 'crypto';

export default function RSAEncrypt(data: string, pubKey = AppConfig.AuthMSPublicKey) {
  const buffer = Buffer.from(data);
  const encrypted = publicEncrypt(pubKey, buffer);
  return encrypted.toString('base64');
}

export const getXSp = (email?: string) =>
  `PTN ${RSAEncrypt(`PTN:${Date.now()}:${email || 'secret'}`, AppConfig.AppPublicKey)}`;
