import Custom403 from 'examples/403';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';
import { useStore } from 'store';

const withAuthorization =
  (condition: (authUser: TConditionArgs) => boolean) =>
  <P extends Record<string, any>>(Component: React.ComponentType<P>): FC<P> =>
    observer((props) => {
      const {
        AuthStore: { Permissions, tenantDetails }
      } = useStore();
      return condition({ ...tenantDetails, Permissions }) ? (
        <Component {...(props as P)} />
      ) : (
        <Custom403 />
      );
    });

export default withAuthorization;
