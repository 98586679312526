import Grid from '@mui/material/Grid';
import MDBox from 'components/MDBox';
import PageLayout from 'examples/LayoutContainers/PageLayout';
import AuthLayout from './AuthLayout';

function BasicLayout({ children }: IBasicLayoutProps) {
  return (
    <PageLayout background={'#f0f2f5' as 'light'}>
      <MDBox
        position="absolute"
        width="100%"
        minHeight="100vh"
        sx={{
          backgroundImage: `url('/brand/section-figure-2@2x.png')`,
          backgroundSize: '115%',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          color: '#344767 !important'
        }}
      />
      <MDBox px={1} width="100%" height="100vh" mx="auto">
        <Grid container spacing={1} justifyContent="center" alignItems="center" height="100%">
          <Grid item xs={11} sm={9} md={5} lg={4} xl={3}>
            {children}
          </Grid>
        </Grid>
      </MDBox>
    </PageLayout>
  );
}

// Typechecking props for the BasicLayout
interface IBasicLayoutProps {
  children: React.ReactNode;
}

export default BasicLayout;

export { AuthLayout };
