import { auth } from 'constants/api';
import client from '..';

interface ILogoutResponse {
  accessToken: string;
}

const call = () => client.get<ILogoutResponse>(auth.logout);

export default call;
