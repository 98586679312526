import React from 'react';
import ArrowImage from 'assets/essenza/arrow.svg';
import FacebookImage from 'assets/essenza/facebook.svg';
import InstagramImage from 'assets/essenza/instagram.svg';
import TwitterImage from 'assets/essenza/twitter.svg';
import LinkedInImage from 'assets/essenza/linkedin.svg';
import MDBox from './MDBox';
import MDTypography from './MDTypography';

function Footer() {
  return (
    <MDBox sx={{ backgroundColor: '#F8F8F8 !important', h: '100%' }}>
      <MDBox
        display="flex"
        justifyContent="space-around"
        alignItems="baseline"
        p={{ xs: '1.5rem', md: '0rem', lg: '4rem' }}
        sx={{
          flexDirection: { xs: 'column', md: 'column', lg: 'row' }
        }}
        textAlign="left"
      >
        <MDBox mb={{ xs: '2rem', md: '2rem', lg: '0' }} mt={{ xs: '2rem', md: '2rem', lg: '0' }}>
          <MDTypography
            variant="h1"
            sx={{
              fontSize: '18px',
              fontWeight: '600',
              lineHeight: '22px',
              color: '#033E2F',
              fontFamily: 'Inter'
            }}
          >
            CONTACT
          </MDTypography>
          <MDBox
            display="flex"
            alignItems="center"
            mt={{ xs: '1rem', md: '1rem', lg: '2rem' }}
            justifyContent="start"
          >
            <MDTypography>Main website</MDTypography>
            <ArrowImage />
          </MDBox>
          <MDTypography>support@investbamboo.com</MDTypography>
        </MDBox>
        <MDBox mb={{ xs: '2.5rem', md: '2rem', lg: '0' }}>
          <MDTypography
            variant="h1"
            fontSize="18px"
            sx={{
              fontWeight: '600',
              lineHeight: '22px',
              color: '#033E2F'
            }}
            fontFamily="Inter"
          >
            LAGOS
          </MDTypography>
          <MDTypography
            fontSize="18px"
            sx={{
              fontWeight: '400',
              lineHeight: '22px',
              color: '#232323',
              maxWidth: '14rem'
            }}
            fontFamily="Inter"
            mt={{ xs: '1rem', md: '1rem', lg: '2rem' }}
          >
            Plot 1, Block, 22, Babatunde Anjous Ave, Lekki Phase 1, Lagos, Nigeria
          </MDTypography>
        </MDBox>
        <MDBox mb={{ xs: '2.5rem', md: '2rem', lg: '0' }}>
          <MDTypography
            variant="h1"
            fontSize="18px"
            sx={{
              fontWeight: '600',
              lineHeight: '22px',
              color: '#033E2F'
            }}
            fontFamily="Inter"
          >
            SAN FRANCISCO
          </MDTypography>
          <MDTypography
            fontSize="18px"
            sx={{
              fontWeight: '400',
              lineHeight: '22px',
              color: '#232323',
              maxWidth: '14rem'
            }}
            fontFamily="Inter"
            mt={{ xs: '1rem', md: '1rem', lg: '2rem' }}
          >
            490 Post Street, Ste. 526 San Francisco, CA 94102
          </MDTypography>
        </MDBox>
        <MDBox mb={{ xs: '2.5rem', md: '2rem', lg: '0' }}>
          <MDTypography
            variant="h1"
            fontSize="18px"
            sx={{
              fontWeight: '600',
              lineHeight: '22px',
              color: '#033E2F'
            }}
            fontFamily="Inter"
          >
            SOCIAL
          </MDTypography>
          <MDBox
            display="flex"
            mt={{ xs: '1rem', md: '1rem', lg: '2rem' }}
            minWidth="200px"
            justifyContent="space-between"
            alignItems="center"
          >
            <MDBox>
              <FacebookImage />
            </MDBox>
            <MDBox>
              <InstagramImage />
            </MDBox>
            <MDBox>
              <TwitterImage />
            </MDBox>
            <MDBox>
              <LinkedInImage />
            </MDBox>
          </MDBox>
        </MDBox>
      </MDBox>
    </MDBox>
  );
}
export default Footer;
