import { lifeTimeTradeSum, TRADES } from 'constants/api';
import { rangeData } from 'constants/dateRange';
import client from 'requests';

const range = rangeData.find((item) => item.label === 'Last 7 Days');

export const defaultData = {
  page: 1,
  limit: 25,
  start_date: range.startDate.valueOf(),
  end_date: range.endDate.valueOf()
};

const call = (payload: Partial<IPaginationConfig & { user_id: string }>) =>
  client.get<IPaginatedEndpointResp<ITradesHistory>>(TRADES.GET_US, {
    params: { ...defaultData, ...payload }
  });

export const getNgxTrades = (payload: Partial<IPaginationConfig & { user_id: string }>) =>
  client.get<IPaginatedEndpointResp<INgxTradesHistory>>(TRADES.GET_NGX, {
    params: { ...defaultData, ...payload }
  });

export const getSummaryStat = (
  start_date = range.startDate.valueOf(),
  end_date = range.endDate.valueOf()
) =>
  client.get<ITradesSummaryStat>(TRADES.GET_US_STAT, {
    params: { end_date, start_date }
  });

export const getNgxSummaryStat = (
  start_date = range.startDate.valueOf(),
  end_date = range.endDate.valueOf()
) => client.get<ITradesSummaryStat>(TRADES.GET_NGX_STAT, { params: { end_date, start_date } });

export const getLifeTimeTradeSum = () => client.get<{ sum: string }>(lifeTimeTradeSum);

export interface ITradesSummaryStat {
  range: {
    start_date: number;
    end_date: number;
  };
  returnedSum: {
    buy_sum: number;
    sell_sum: number;
  };
}

export default call;

export interface ITradesHistory {
  id: string;
  inserted_at: string;
  price_per_share: string;
  side: 'buy' | 'sell';
  stock_symbol: string;
  transaction_value: string;
  type: string;
  quantity: string;
  dollar_fee: string;
  status: TTradeStatus;
  user_currency_fee: string;
  user_currency_transaction_value: string;
  user_currency_price_per_share: string;
  user?: IUserShortDetails;
  naira_fee: string;
  user_id: string;

  // maybe removed
  dw_order_id?: string;
  updated_at?: string;
  api_consumer_id?: number;
}

export interface INgxTradesHistory {
  id: string;
  api_consumer_id: number;
  broker_id: number;
  currency: string;
  dollar_fee: number;
  fee?: number;
  dollar_price_per_share: number;
  dollar_txn_value: number;
  exchange_rate: number;
  lsx_account: number;
  lsx_account_id: number;
  lsx_order_id: string;
  price_per_share: number;
  quantity: number;
  side: 'buy' | 'sell';
  status: TTradeStatus;
  stock_id: number;
  stock_symbol: string;
  txn_value: number;
  type: string;
  inserted_at: string;
  updated_at: string;
  broker_code: string;
  price: number;
  wallet_id: null;
  order_value: number;
  user_id: number;
  user: IUserShortDetails;
}
