import Icon from '@mui/material/Icon';
import rbac from 'constants/permissions';
import { Page } from 'constants/route';

const routes = (Permissions: string[]) => [
  {
    type: 'collapse',
    name: 'Dashboard',
    key: 'dashboard',
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: Page.dashboard,
    can: rbac(Permissions).PTN_ADMIN_DASHBOARD_READ
  },
  {
    type: 'collapse',
    name: 'US Trades',
    key: 'trades',
    icon: <Icon fontSize="small">table_view</Icon>,
    route: Page.usTrades,
    can: rbac(Permissions).PTN_ADMIN_TRADE_US_READ
  },
  {
    type: 'collapse',
    name: 'NGX Trades',
    key: 'trades',
    icon: <Icon fontSize="small">table_view</Icon>,
    route: Page.ngxTrades,
    can: rbac(Permissions).PTN_ADMIN_TRADE_NGX_READ
  },
  {
    type: 'collapse',
    name: 'Deposits',
    key: 'deposit',
    icon: <Icon fontSize="small">receipt_long</Icon>,
    route: Page.deposits,
    can: rbac(Permissions).PTN_ADMIN_DEPOSIT_READ
  },
  {
    type: 'collapse',
    name: 'Withdrawals',
    key: 'withdrawals',
    icon: <Icon fontSize="small">money</Icon>,
    route: Page.withdrawals,
    can: rbac(Permissions).PTN_ADMIN_WITHDRAWAL_READ
  },
  {
    type: 'collapse',
    name: 'Users',
    key: 'users',
    icon: <Icon fontSize="small">group</Icon>,
    route: Page.users,
    can: rbac(Permissions).PTN_ADMIN_USER_READ
  }
];

export default routes;
