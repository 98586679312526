import isAuthorized from 'utils/parsePermission';

export const PERMISSIONS = {
  PTN_ADMIN_USER_READ: 'ptn.admin.user.read',
  PTN_ADMIN_DASHBOARD_READ: 'ptn.admin.dashboard.read',
  PTN_ADMIN_TRADES_READ: 'ptn.admin.trades.read',
  PTN_ADMIN_TRADE_NGX_READ: 'ptn.admin.trades.ngx.read',
  PTN_ADMIN_TRADE_US_READ: 'ptn.admin.trades.us.read',
  PTN_ADMIN_DEPOSIT_READ: 'ptn.admin.deposit.read',
  PTN_ADMIN_WITHDRAWAL_READ: 'ptn.admin.withdrawal.read',
  PTN_ADMIN_DEPOSIT_CSV_READ: 'ptn.admin.deposit.csv.read',
  PTN_ADMIN_WITHDRAWAL_CSV_READ: 'ptn.admin.withdrawal.csv.read',
  PTN_ADMIN_TRADE_CSV_READ: 'ptn.admin.trade.csv.read',
  PTN_ADMIN_USER_CSV_READ: 'ptn.admin.user.csv.read'
} as const;

export type TPERMISSIONS = typeof PERMISSIONS[keyof typeof PERMISSIONS];

/**
 * ### Role Based Access Control (RBAC)
 * @param userPermissions users permissions
 * @returns  an object with the same keys as PERMISSIONS and boolean values
 */
export default function rbac(userPermissions: string[]) {
  return Object.keys(PERMISSIONS).reduce((acc, key) => {
    const permission = PERMISSIONS[key as keyof typeof PERMISSIONS];
    acc[key as keyof typeof PERMISSIONS] = isAuthorized(permission, userPermissions);
    return acc;
  }, {} as Record<keyof typeof PERMISSIONS, boolean>);
}

export const PermissionCode = {
  [PERMISSIONS.PTN_ADMIN_USER_READ]: 'P1001',
  [PERMISSIONS.PTN_ADMIN_DASHBOARD_READ]: 'P1002',
  [PERMISSIONS.PTN_ADMIN_TRADES_READ]: 'P1003',
  [PERMISSIONS.PTN_ADMIN_TRADE_NGX_READ]: 'P1004',
  [PERMISSIONS.PTN_ADMIN_TRADE_US_READ]: 'P1005',
  [PERMISSIONS.PTN_ADMIN_DEPOSIT_READ]: 'P1006',
  [PERMISSIONS.PTN_ADMIN_WITHDRAWAL_READ]: 'P1007',
  [PERMISSIONS.PTN_ADMIN_DEPOSIT_CSV_READ]: 'P1008',
  [PERMISSIONS.PTN_ADMIN_WITHDRAWAL_CSV_READ]: 'P1009',
  [PERMISSIONS.PTN_ADMIN_TRADE_CSV_READ]: 'P1010',
  getCode(permission: TPERMISSIONS) {
    return this[permission];
  }
};
