import Logout from '@mui/icons-material/Logout';
import Settings from '@mui/icons-material/Settings';
import { Divider } from '@mui/material';
import Icon from '@mui/material/Icon';
import IconButton from '@mui/material/IconButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import { setDarkMode, useMaterialUIController } from 'context';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import { observer } from 'mobx-react-lite';
import { useRouter } from 'next/router';
import { useStore } from 'store';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorFallback from 'components/ErrorFallback';
import { navbarIconButton } from './styles';

const MenuPopupState = () => {
  const [controller, dispatch] = useMaterialUIController();
  const { transparentNavbar, darkMode } = controller;
  const {
    AuthStore: { logout, AuthUser }
  } = useStore();

  const route = useRouter();

  const handleDarkMode = () => setDarkMode(dispatch, !darkMode);

  const iconsStyle = ({ palette: { dark, white, text }, functions: { rgba } }) => ({
    color: () => {
      let colorValue = darkMode ? white.main : dark.main;

      if (transparentNavbar) {
        colorValue = darkMode ? rgba(text.main, 0.6) : text.main;
      }

      return colorValue;
    }
  });
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <PopupState variant="popover" popupId="demo-popup-menu">
        {(popupState) => (
          <>
            <IconButton
              {...bindTrigger(popupState)}
              sx={navbarIconButton}
              size="small"
              disableRipple
            >
              <Icon sx={iconsStyle}>account_circle</Icon>
            </IconButton>
            <Menu {...bindMenu(popupState)}>
              <MDBox display="flex" justifyContent="center">
                <MDTypography
                  component="h5"
                  variant="h5"
                  fontSize="inherit"
                  p="0 1rem"
                  color="text"
                  mb="0.3rem"
                  mt="0.4rem"
                >
                  {AuthUser?.email}
                </MDTypography>
              </MDBox>
              <Divider
                light={!darkMode}
                sx={(theme) => ({
                  backgroundImage: `${theme.functions.linearGradient3scale(
                    '#fff0',
                    theme.palette.logo.bamboo
                  )} !important`
                })}
              />
              <MenuItem
                onClick={() => {
                  handleDarkMode();
                  popupState.close();
                }}
              >
                <ListItemIcon>
                  <Icon color="primary">{darkMode ? 'light_mode' : 'dark_mode'}</Icon>
                </ListItemIcon>
                <MDBox component="span" color="text">
                  {darkMode ? 'Light Mode' : 'Dark Mode'}
                </MDBox>
              </MenuItem>
              <MenuItem
                onClick={() => {
                  popupState.close();
                  route.push('/settings');
                }}
              >
                <ListItemIcon>
                  <Settings fontSize="small" color="primary" />
                </ListItemIcon>
                Settings
              </MenuItem>
              <MenuItem
                onClick={() => {
                  logout();
                  popupState.close();
                }}
              >
                <ListItemIcon>
                  <Logout fontSize="small" color="primary" />
                </ListItemIcon>
                Logout
              </MenuItem>
            </Menu>
          </>
        )}
      </PopupState>
    </ErrorBoundary>
  );
};

export default observer(MenuPopupState);
