/**
  This file is used for controlling the global states of the components,
  you can customize the states for the different components here.
*/

import { createContext, Dispatch, FC, useContext, useMemo, useReducer } from 'react';
import { TColor } from 'types/mui-theme';
import { IContollerState, TControllerReducer } from './type';

const MaterialUI = createContext<[IContollerState, Dispatch<TControllerReducer>]>(null);
MaterialUI.displayName = 'PBBambooUIContext';

function reducer(state: IContollerState, action: TControllerReducer): IContollerState {
  switch (action.type) {
    case 'MINI_SIDENAV': {
      return { ...state, miniSidenav: action.value };
    }
    case 'TRANSPARENT_SIDENAV': {
      return { ...state, transparentSidenav: action.value };
    }
    case 'WHITE_SIDENAV': {
      return { ...state, whiteSidenav: action.value };
    }
    case 'SIDENAV_COLOR': {
      return { ...state, sidenavColor: action.value };
    }
    case 'TRANSPARENT_NAVBAR': {
      return { ...state, transparentNavbar: action.value };
    }
    case 'FIXED_NAVBAR': {
      return { ...state, fixedNavbar: action.value };
    }
    case 'OPEN_CONFIGURATOR': {
      return { ...state, openConfigurator: action.value };
    }
    case 'LAYOUT': {
      return { ...state, layout: action.value };
    }
    case 'DARKMODE': {
      return { ...state, darkMode: action.value };
    }
    default: {
      return state;
    }
  }
}

// Material Dashboard 2 PRO React context provider
const PBBambooUIControllerProvider: FC = ({ children }) => {
  const initialState: IContollerState = {
    miniSidenav: false,
    transparentSidenav: false,
    whiteSidenav: false,
    sidenavColor: 'info',
    transparentNavbar: true,
    fixedNavbar: true,
    openConfigurator: false,
    layout: 'dashboard',
    darkMode: false
  };

  const [controller, dispatch] = useReducer(reducer, initialState);

  const value = useMemo(
    () => [controller, dispatch] as [IContollerState, Dispatch<TControllerReducer>],
    [controller]
  );

  return <MaterialUI.Provider value={value}>{children}</MaterialUI.Provider>;
};

// Material Dashboard 2 PRO React custom hook for using context
function useMaterialUIController() {
  const context = useContext(MaterialUI);

  if (!context) {
    throw new Error(
      'useMaterialUIController should be used inside the PBBambooUIControllerProvider.'
    );
  }

  return context;
}

// Context module functions
const setMiniSidenav = (dispatch: Dispatch<TControllerReducer>, value: boolean) =>
  dispatch({ type: 'MINI_SIDENAV', value });
const setTransparentSidenav = (dispatch: Dispatch<TControllerReducer>, value: boolean) =>
  dispatch({ type: 'TRANSPARENT_SIDENAV', value });
const setWhiteSidenav = (dispatch: Dispatch<TControllerReducer>, value: boolean) =>
  dispatch({ type: 'WHITE_SIDENAV', value });
const setSidenavColor = (dispatch: Dispatch<TControllerReducer>, value: TColor) =>
  dispatch({ type: 'SIDENAV_COLOR', value });
const setTransparentNavbar = (dispatch: Dispatch<TControllerReducer>, value: boolean) =>
  dispatch({ type: 'TRANSPARENT_NAVBAR', value });
const setFixedNavbar = (dispatch: Dispatch<TControllerReducer>, value: boolean) =>
  dispatch({ type: 'FIXED_NAVBAR', value });
const setOpenConfigurator = (dispatch: Dispatch<TControllerReducer>, value: boolean) =>
  dispatch({ type: 'OPEN_CONFIGURATOR', value });
const setLayout = (dispatch: Dispatch<TControllerReducer>, value: string) =>
  dispatch({ type: 'LAYOUT', value });
const setDarkMode = (dispatch: Dispatch<TControllerReducer>, value: boolean) =>
  dispatch({ type: 'DARKMODE', value });

export {
  PBBambooUIControllerProvider,
  useMaterialUIController,
  setMiniSidenav,
  setTransparentSidenav,
  setWhiteSidenav,
  setSidenavColor,
  setTransparentNavbar,
  setFixedNavbar,
  setOpenConfigurator,
  setLayout,
  setDarkMode
};
