export const Page = {
  dashboard: '/dashboard',
  ngxTrades: '/trades/ngx',
  usTrades: '/trades',
  deposits: '/deposit',
  withdrawals: '/withdrawals',
  users: '/users',
  Auth: {
    login: '/auth/login'
  }
} as const;
