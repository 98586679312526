import { TXN } from 'constants/api';
import { rangeData } from 'constants/dateRange';
import client from 'requests';
import { defaultData } from 'requests/trades';

const range = rangeData.find((item) => item.label === 'Last 7 Days');

const call = (payload: Partial<IPaginationConfig & { user_id: string }>) =>
  client.get<IPaginatedEndpointResp<IDeposit>>(TXN.GET_DEPOSITS, {
    params: { ...defaultData, ...payload }
  });

export default call;

export const getSummaryStat = (
  start_date = range.startDate.valueOf(),
  end_date = range.endDate.valueOf()
) =>
  client.get<IDepositSummaryStat>(TXN.GET_DEPOSITS_SUMMARY, { params: { end_date, start_date } });

export interface IDepositSummaryStat {
  range: {
    start_date: number;
    end_date: number;
  };
  stat: 'days' | 'weeks' | 'months';
  data: {
    id: number;
    sum: string | null;
  }[];
}

export interface IDeposit {
  id: string;
  status: string;
  inserted_at: string;
  dollar_amount: string;
  exchange_rate: string;
  deposit_type: string;
  dollar_instant_deposit_fee: string;
  dollar_processing_fee: string;
  reference: string;
  user_id: string;
  user: {
    id: string;
    identity_document: {
      id: string;
      full_name: string;
      user_id: number;
    };
  };
}
