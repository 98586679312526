import React, { createContext, useContext } from 'react';
import DashboardStore from './Dashboard';
import DepositStore from './DepositStore';
import AuthStore from './AuthStore';
import TradeStore from './TradesHistoryStore';
import UserStore from './UserStore';
import WithdrawalStore from './WithdrawalStore';

export class RootStore {
  WithdrawalStore: WithdrawalStore;

  AuthStore: AuthStore;

  TradeStore: TradeStore;

  DashboardStore: DashboardStore;

  DepositStore: DepositStore;

  UserStore: UserStore;

  constructor() {
    this.WithdrawalStore = new WithdrawalStore(this);
    this.AuthStore = new AuthStore(this);
    this.TradeStore = new TradeStore(this);
    this.DashboardStore = new DashboardStore(this);
    this.DepositStore = new DepositStore(this);
    this.UserStore = new UserStore(this);
  }
}

interface StoreProviderProps {
  children: React.ReactNode;
}

/**
 * ### Root Store Instance
 * This is the root store instance.
 * It is used to access all the stores.
 * and can be used to access the store properties outside of the component.
 */
export const Stores = new RootStore();

const StoreContext = createContext<RootStore>(Stores);

export const StoreProvider = ({ children }: StoreProviderProps) => (
  <StoreContext.Provider value={Stores}>{children}</StoreContext.Provider>
);

export const useStore = () => useContext(StoreContext);

export default StoreProvider;
