import { CircularProgress, Typography } from '@mui/material';
import MDBox from 'components/MDBox';

const LoadingPage = ({ fullPage = false }) => (
  <MDBox
    flexDirection="column"
    height="100%"
    {...(fullPage && { minHeight: '100vh' })}
    alignItems="center"
    display="flex"
    justifyContent="center"
  >
    <CircularProgress data-testid="LoadingPage" color="success" size={300} thickness={2} />
    {fullPage && (
      <Typography
        color="primary.hover"
        variant="h4"
        sx={{ span: { color: 'text.gray' }, mt: '1.5rem' }}
      >
        Bamboo Partners
      </Typography>
    )}
  </MDBox>
);

export default LoadingPage;
