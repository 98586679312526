/* eslint-disable no-console */
import _ from 'lodash';

const debouncedLog = _.debounce(console.log, 5000);

function destructurePermission(permission: string) {
  const permArr = permission.split('.').map((str) => str.toLowerCase());
  if (permArr.length < 2) {
    if (process.env.NODE_ENV === 'development') {
      console.log({
        title: 'Invalid permission',
        message: `"${permArr.join(
          '.'
        )}" is invalid! Permission must be in the format of "resource.action"`,
        id: 'invalid-permission'
      });
    }
    return [];
  }

  const actions = {
    '*': ['read', 'update', 'create', 'delete', 'write'],
    read: ['read', 'update', 'create', 'delete', 'write'],
    update: ['update', 'create', 'delete', 'write'],
    create: ['create', 'delete', 'write'],
    delete: ['delete', 'write'],
    write: ['write']
  };

  type Action = keyof typeof actions;

  const action = permArr.pop() || '';
  const baseResource = permArr[0];
  const resource = permArr.join('.');

  return permArr.length > 1
    ? [...actions[action as Action].map((perm) => `${resource}.${perm}`), `${baseResource}.write`]
    : actions[action as Action].map((perm) => `${resource}.${perm}`);
}

export default function isAuthorized(
  requiredPermission: string | string[],
  userPermissions: string[]
) {
  if (process.env.NODE_ENV === 'development') {
    userPermissions.forEach((p) => {
      if (p.split('.').length < 2)
        console.log({
          title: 'Invalid permission',
          message: `"${p}" is invalid! Permission must be in the format of "<resource>.<action>"`,
          id: 'invalid-permission'
        });
    });
  }
  if (process.env.NODE_ENV === 'development') {
    debouncedLog(
      'requiredPermission::',
      `"${requiredPermission}"`,
      '\n\nuserPermissions::',
      userPermissions
    );
  }

  const permissions = Array.isArray(requiredPermission)
    ? requiredPermission
    : destructurePermission(requiredPermission);

  if (!permissions.length) return false;
  return permissions.some((permission) => userPermissions.includes(permission));
}
