import { getWithdrawals, getWithdrawalSummaryStat } from 'constants/api';
import { rangeData } from 'constants/dateRange';
import client from 'requests';

const range = rangeData.find((item) => item.label === 'Last 7 Days');

const call = (
  page = 1,
  limit = 25,
  start_date = range.startDate.valueOf(),
  end_date = range.endDate.valueOf()
) =>
  client.get<IPaginatedEndpointResp<IWithdrawal>>(
    getWithdrawals({ page, limit, start_date, end_date })
  );

export const getSummaryStat = (
  start_date = range.startDate.valueOf(),
  end_date = range.endDate.valueOf()
) => client.get<IWithdrawalSummaryStat>(getWithdrawalSummaryStat({ end_date, start_date }));

export interface IWithdrawalSummaryStat {
  range: {
    start_date: number;
    end_date: number;
  };
  stat: 'days' | 'weeks' | 'months';
  data: {
    id: number;
    sum: number;
  }[];
}

export default call;

export interface IWithdrawal {
  id: string;
  account_number: string;
  additional_instructions: string | null;
  amount: string;
  bank_name: string;
  currency: string;
  fees: string;
  status: string;
  updated_at: string;
  exchange_rate_value: string;
  inserted_at: string;
  user_id: string;
  user?: IUserShortDetails;

  // Considered for removal
  intermediary_bank?: null;
  intermediary_bank_swift_code?: null;
  swift_aba_code?: null;
  api_consumer_id?: 1;
  bank_zip_code?: null;
  beneficiary_name?: null;
  bank_state?: null;
  bank_address?: string | null;
  bank_code?: string;
  bank_country?: null;
  bank_fw_id?: string;
}
